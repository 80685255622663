<template>
  <div class="animsition page-error page-error-404 layout-full">
    <div class="page vertical-align text-center" data-animsition-in="fade-in" data-animsition-out="fade-out">
      <div class="page-content vertical-align-middle">
        <header>
          <h1 class="animation-slide-top">400</h1>
          <p>Bad Request !</p>
        </header>
        <p class="error-advise">YOU SEEM TO BE TRYING TO FIND HIS WAY HOME</p>
        <el-button round size="small" type="primary" @click="showHome()">回到主页</el-button>

        <footer class="page-copyright">
          <p>WEBSITE BY Cspiration</p>
        </footer>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  methods: {
    showHome() {
      this.$router.push("/");
    }
  }
}
</script>

<style scoped>
@import url("./../../assets/css/error.css");

.house-header, .house-footer {
  display: none !important;
}

.layout-full .page {
  height: 100vh;
  text-align: center;
  padding: 10%;
}

.page-content {
  padding: 0;
  margin: auto auto;
}

.btn {
  border: 1px solid black !important;
  border-radius: 100px !important;
}
</style>
